.hero_section {
  background-color: black;
}

.hero__wrapper {
  display: flex;
  justify-content: space-between;
}

.hero__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.hero__content .section__title {
  font-size: 5rem;
  color: #fd6600;
  text-align: end;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.highlights {
  color: white;
}

.hero__content p {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-align: end;
  text-transform: uppercase;
}

.hero__btns {
  display: flex;
  align-items: center;
  column-gap: 3rem;
}

.watch__btn {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.watch__btn span {
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  color: var(--primary-color);
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 8px -1px #d3cef2;
}

.hero__img-wrapper {
  position: relative;
  width: 500px;
  z-index: 1;
}

.box__img {
  display: flex;
  justify-content: center;
  z-index: 10;
}

.box__img img {
  width: 50%;
  height: 50%;
}

.gym__location {
  position: absolute;
  top: 15%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%) !important;
  text-align: center;
}

.gym__location img {
  width: 200px;
  height: 200px;
}

.gym__registration {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  border-radius: 4px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.5);
  width: 200px;
  height: 50px;
  background-color: #fd6600;
  z-index: 10;
  cursor: pointer;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out !important;
}

.gym__registration:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: #ffa629;
  left: 0;
  bottom: 0;
  transition: all 0.4s;
  opacity: 0.4;
}

.gym__registration:hover:before {
  width: 100%;
}

.gym__registration span {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.6s;
}

.gym__registration:hover span {
  color: white;
}
