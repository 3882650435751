.wrapper {
  padding-top: 75px
}

.exercise__top {
  text-align: center;
}

.exercise__top h2 {
  font-size: 3rem;
}
