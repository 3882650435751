.header {
  display: flex;
  width: 100%;
  background-color: #ffa628;
  height: 100px;
  line-height: 70px;
}

.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.logo__img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.logo__img img {
  border-radius: 10px;
  width: 100%;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav__item a {
  color: black;
  font-weight: 600 !important;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1.5rem;
}

.nav__item a:hover {
  color: var(--primary-color);
}

.nav__right {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.sticky__header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}
